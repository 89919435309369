import styles from './resume.module.scss';

export default function ResumePage() {
	return <div className={styles.wrapper}>
        <div className={styles.leftside}>
            <div className={styles.avatar}>
                
            </div>
        </div>
        <div className={styles.content}>
            
        </div>
    </div>;
}
