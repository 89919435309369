import s from './mainblock.module.scss';
import { FaArrowRight } from 'react-icons/fa';
import codeDarkImage from './code(dark).png';
import codeLightImage from './code.png'
import { ButtonDefault } from '../../../../Components/UI kit/Buttons/Default button/ButtonDefault';
import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { openModal } from '../../../../Redux/reducers/MainModalReducer';

export default function MainBlockForHomePage() {
	const dispatch = useDispatch();

	return (
		<>
			<button
				className={s.coloredButton}
				onClick={() => dispatch(openModal())}
			>
				<p>
					Вы можете получить лендинг <span>бесплатно.</span> Узнайте как
				</p>
				<FaArrowRight className={s.coloredButtonIcon} />
			</button>
			<div className={s.bg}>
				<div className={s.plus}></div>
				<div className={s.plus}></div>
				<div className={s.plus}></div>
				<div className={s.plus}></div>
				<div>
					<h1 className={s.title}>
						your personal developer
						<div className={s.titleDot}>
							<div className={s.title}>.</div>
						</div>
					</h1>
					<p className={s.subtitle}>
						Полноценная веб-разработка и ведение проектов. Концентрируйтесь на своем бизнесе, а мы{' '}
						<span> обеспечим успешное развитие</span> в сети.
					</p>
				</div>

				<div className={s.btns}>
					<Link tabIndex={1} to="/products">
						<ButtonDefault>Готовые продукты</ButtonDefault>
					</Link>
					<button>
						<Link tabIndex={1} to="/services">
							Услуги
						</Link>
					</button>
				</div>
				<div className={s.codeImages}>
					<img className={s.img} height={400} width={631} src={codeDarkImage} alt="" />
					<img className={s.img} height={400} width={715} src={codeLightImage} alt="" />
				</div>
			</div>
		</>
	);
}
