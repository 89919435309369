import { Link } from 'react-router-dom';
import Logo from '../logo';
import styles from './header.module.scss';
import { useSelector } from 'react-redux';

import { ButtonDefault } from '../UI kit/Buttons/Default button/ButtonDefault';

import { IoIosCloseCircle } from 'react-icons/io';
import { IoMenuOutline, IoPersonCircle } from 'react-icons/io5';
import { RiLoginCircleFill } from 'react-icons/ri';

import { useState } from 'react';

export default function Header({ propStyles }) {
	const user = useSelector((state) => state.user);
	const [menu, setMenu] = useState(false);
	const nav = [
		{
			title: 'Услуги',
			path: '/services',
			ariaLabel: 'Перейти на страницу с услугами'
		},
		{
			title: 'Наши работы',
			path: '/portfolio',
			ariaLabel: 'Перейти на страницу с портфолио'
		},
		{
			title: 'Команда',
			path: '/team',
			ariaLabel: 'Перейти на страницу с нашей командой'
		}
	]

	return (
		<div className={styles.outer} style={propStyles}>
			<div className={styles.wrapper}>
				<Link className={styles.logo} to="/">
					<Logo width={50} />
					<p className={styles.logoText}>eb assist</p>
				</Link>

				<nav className={`${styles.nav} ${menu ? null : styles.navHider}`}>
					{
						nav.map((navItem) => {
							return (
								<Link key={navItem.title} aria-label="Перейти на страницу с услугами" to={navItem.path} className={styles.navLink}>
									{navItem.title}
								</Link>
							)
						})
					}

					<div className={styles.navButtons}>
						<IoIosCloseCircle
							onClick={() => {
								setMenu(false);
							}}
							className={styles.closeBtn}
						/>
						{user.isAuth ? (
							<IoPersonCircle className={styles.profileBtn} />
						) : (
							<div className={styles.profileBtnBlock}>
								<RiLoginCircleFill className={styles.profileBtn} />
								войти
							</div>
						)}
					</div>
					{/*  */}
					{/* <Link
						className={styles.loginBtn}
						tabIndex={1}
						to={user.isAuth ? '/profile' : '/login'}
					>
						<ButtonDefault className={styles.btn} propStyle={{ width: '140px' }}>
							{user.isAuth ? 'Профиль' : 'Войти'}
						</ButtonDefault>
					</Link> */}
				</nav>

				<div className={styles.menu}>
					<IoMenuOutline
						onClick={() => {
							setMenu(true);
						}}
						className={styles.burgerIcon}
						size={30}
						color="white"
					/>
				</div>
			</div>
		</div>
	);
}
