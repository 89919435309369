import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import store from './Redux/store';

// styles
import './index.scss';

// Pages
import HomePage from './Pages/HomePage/HomePage';
import ResumePage from './Pages/ResumePage/ResumePage';
import PortfolioPage from './Pages/PortfolioPage/PortfolioPage';
import { Provider } from 'react-redux';
import NotFoundPage from './Pages/404/404';

const root = ReactDOM.createRoot(document.getElementById('root'));
export const homeUrl = 'https://web-assist.ru';
const router = createBrowserRouter([
	{
		path: '/',
		element: <HomePage />,
	},
	{
		path: '/portfolio',
		element: <PortfolioPage />,
	},
	{
		path: '/resume',
		element: <ResumePage />,
	},
	{
		path: '*',
		element: <NotFoundPage />,
	},
]);

root.render(
	<Provider store={store}>
			<RouterProvider router={router} />
	</Provider>,
);
