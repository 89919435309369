import { createSlice } from '@reduxjs/toolkit';

const MainModalSlice = createSlice({
	name: 'modal',
	initialState: false,
	reducers: {
		openModal: (state) => {
			document.body.classList.add('body-without-scroll');
			return true
		},
		closeModal: (state) => {
			document.body.classList.remove('body-without-scroll');
			return false;
		},
	},
});


export const {openModal, closeModal} = MainModalSlice.actions
export default MainModalSlice.reducer