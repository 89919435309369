import { useState, useEffect } from 'react';
import Header from '../../Components/Header/Header';
import styles from './port.module.scss';
import { Helmet } from 'react-helmet';

import starsLottieJSON from '../../Assets/Lottie/stars.json';
import Lottie from 'lottie-react';
import advertImage from '../../Assets/Images/ADVERT-DIGITAL.png';

export default function PortfolioPage() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Helmet>
				<title>Наши работы || web-assist</title>
				<meta name="description" content="asdf" />
			</Helmet>
			{/* <Header propStyles={{ position: 'relative' }} /> */}
			<div className={styles.wrapperOuter}>
				<div className={styles.section}>
					<Lottie className={styles.lottieStars} animationData={starsLottieJSON} />
					<div className={styles.wrapper}>
						<div className={styles.titles}>
							<h1 className={styles.title}>our portfolio</h1>
							<h1 className={styles.title}>our portfolio</h1>
							<h1 className={styles.title}>our portfolio</h1>
							<h1 className={styles.title}>our portfolio</h1>

							<h1 className={styles.visibleTitle}>
								Создаем
								<br />
								<span>красивые сайты</span>
							</h1>
						</div>
					</div>
				</div>
				<div className={styles.section}>
					<div className={`${styles.wrapper} ${styles.advertWrapper}`}>
						<div>
							<h1>
								<a target="_blank" href="https://addigitalcasting.com">
									advert digital
								</a>
							</h1>

							{window.innerWidth < 1200 && (
								<div className={styles.leftSide}>
									<img src={advertImage} alt="" className={styles.advertImage} />
								</div>
							)}

							<p>
								Агентство работает в 27 странах и активно занимается рекрутинговой деятельностью. Мы помогли штатному дизайнеру
								создать макет в figma. Сделали адаптивную верстку и запустили вебсайт. Загрузка сайта на хостинг не вызвала у
								заказчика никаких проблем, ведь мы сопровождали сайт до полного запуска.
							</p>
							<p>
								Также мы написали удобную web-crm. Она исчерпывает все потребности заказчика и позволяет быстро и удобно обрабатывать
								заявки.
							</p>
							<a href="https://addigitalcasting.com" target="_blank">
								<button>Перейти на сайт</button>
							</a>
						</div>
						{window.innerWidth > 1200 && (
							<div className={styles.leftSide}>
								<img src={advertImage} alt="" className={styles.advertImage} />
							</div>
						)}
					</div>
				</div>

				<div className={styles.section}>
					{' '}
					<div className={styles.wrapper}>Данный раздел еще находится в разработке, попробуйте вернуться позже =)</div>
				</div>
			</div>
		</>
	);
}
