import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/authReducer'
import MainModalReducer from './reducers/MainModalReducer';

const store = configureStore({
    reducer: {
        user: authReducer,
        mainModal: MainModalReducer
    }
})

export default store;