import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        id: '',
        nickname: '',
        name: '',
        email: '',
        lastname: '',
        phone: '',
        isAuth: false,
        plan: 'guest',
        token: '',
    },
    reducers: {
        setUser: (state, { payload }) => {
            state.id = payload.id
            state.nickname = payload.nickname
            state.name = payload.name
            state.lastname = payload.lastname
            state.phone = payload.phone
            state.email = payload.email
            state.isAuth = true
            state.plan = payload.plan
            state.token = payload.token
        },
        logoutUser: (state, actions) => {
            state.id = ''
            state.nickname = ''
            state.name = ''
            state.email = ''
            state.lastname = ''
            state.phone = ''
            state.isAuth = false
            state.plan ='guest'
            state.token = ''
        }
    }
})

export const { setUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;