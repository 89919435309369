export default function Logo ({ width }) {
    return (
        <svg width={width} height={width / 1.77575} viewBox={"0 0 364 208"} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_19_149)">
            <path fillRule="evenodd" clipRule="evenodd" d="M64 0H4L100 200L132.48 142.667L126.769 130.769L104.32 84L64 0Z" fill="white"/>
            <path d="M126.769 130.769L132.48 142.667L100 200L161.333 92L134 34.6667L104.32 84L126.769 130.769Z" fill="#C0C0C0"/>
            </g>
            <g filter="url(#filter1_d_19_149)">
            <path fillRule="evenodd" clipRule="evenodd" d="M177.333 0H117.333L213.333 200L245.813 142.667L240.103 130.769L217.653 84L177.333 0Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M240.103 130.769L245.813 142.667L266.731 106.544L274.957 92.3381L247.333 34.6667L217.653 84L240.103 130.769Z" fill="#C0C0C0"/>
            </g>
            <g filter="url(#filter2_d_19_149)">
            <path d="M290.666 0H230.666L326.666 200L359.146 142.667L353.436 130.769L290.666 0Z" fill="white"/>
            </g>
            <defs>
            <filter id="filter0_d_19_149" x="0" y="0" width="165.333" height="208" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_19_149"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_19_149" result="shape"/>
            </filter>
            <filter id="filter1_d_19_149" x="113.333" y="0" width="165.623" height="208" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_19_149"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_19_149" result="shape"/>
            </filter>
            <filter id="filter2_d_19_149" x="226.666" y="0" width="136.48" height="208" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_19_149"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_19_149" result="shape"/>
            </filter>
            </defs>
        </svg>
    )
}