import styles from './mainmodal.module.scss';
export default function MainInfoModal() {
	return (
		<div className={styles.wrapperOuter}>
			<div className={styles.overlay}>
				<div className={styles.wrapper}>
					<button className={styles.close}></button>
					<h2 className={styles.title}>Акция</h2>
				</div>
			</div>
		</div>
	);
}
