import { useState, useRef } from 'react';
import styles from './faq.module.scss';

export default function FAQ() {
	const questions = [
		{
			title: 'Во сколько мне обойдется вебсайт?',
			answer: 'Итоговая стоимость зависит от масштабов будущего проекта. Если вам необходимо большое веб-приложение с возможностью масштабирования - от 60 000 руб. Ценник на самые простые лендинги начинается от 2 тысяч рублей.',
			ref: useRef(),
		},
		{
			title: 'Как долго длится разработка?',
			answer: 'Скорость разработки также зависит от масштабов проекта. Лендинги мы разрабатываем от 2 дней, если имеется готовый макет. Более крупные проекты оцениваются индивидуально и разработка занимает от 1 недели',
			ref: useRef(),
		},

		{
			title: 'А вдруг мне не понравится дизайн?',
			answer: 'Прежде чем приступить к разработке мы предоставим вам несколько вариантов концепций сайта. Только после вашего одобрения, мы начнем писать код.',
			ref: useRef(),
		},
		{
			title: 'Будет ли мой сайт адаптивным?',
			answer: 'Да! Сайты созданные у нас всегда адаптируются под все устройства, даже планшеты и телевизоры. Мы предлагаем как адаптивные, так и "резиновые" сайты',
			ref: useRef(),
		},

		{
			title: 'Вы сможете помочь с размещением сайта?',
			answer: 'Разумеется. Мы поможем вам выбрать хостинг провайдера. Проследим чтобы вам не навязали "лишние" услуги. Поможем зарегистрировать домен, получить сертификаты безопасности для сайта и разместим исходники. Ваш сайт будет доступен 24/7. А с вашего позволения, мы просто сделаем все это сами и передадим вам все ключи и доступы с инструкциями.',
			ref: useRef(),
		},
	];

	const [heights, setHeights] = useState(questions.map((item) => 0));

	return (
		<div className={styles.wrapper}>
			<p className={styles.title}>F.A.Q.</p>
			<div>
				{questions.map((item, index) => {
					return (
						<div
							onClick={() => {
								// console.log(item.ref.current.scrollHeight)
								heights[index] === 0
									? setHeights((prev) => {
											const old = [...prev];
											old[index] = item.ref.current.scrollHeight;
											return old;
									  })
									: setHeights((prev) => {
											const old = [...prev];
											old[index] = 0;
											return old;
									  });
							}}
							key={index}
							className={styles.point}
						>
							<div className={styles.title}>
								<span>{item.title}</span>
								<svg style={heights[index] === 0 ? {rotate: '360deg', fill: 'white'} : {rotate: '180deg', fill: 'red'}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
									<path d="m12 15.586-4.293-4.293-1.414 1.414L12 18.414l5.707-5.707-1.414-1.414z"></path>
									<path d="m17.707 7.707-1.414-1.414L12 10.586 7.707 6.293 6.293 7.707 12 13.414z"></path>
								</svg>
							</div>
							<div style={{ maxHeight: heights[index], overflow: 'hidden', transition: '.2s ease-in-out' }}>
								<p
									ref={item.ref}
									className={styles.text}
									dangerouslySetInnerHTML={{ __html: item.answer.replace('\n', '<br />') }}
								></p>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
