import Logo from '../logo';
import styles from './footer.module.scss';
import { Link } from 'react-router-dom';

// icons
import { FaTelegramPlane } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { SiGmail } from "react-icons/si";

export default function Footer() {
	return (
		<div className={styles.outerWrapper}>
			<div className={styles.wrapper}>
				<div className={styles.top}>
					<Link className={styles.logo} to="/">
						<Logo width={window.innerWidth < 575 ? 40 : 50} />
						<p className={styles.logoText}>eb assist</p>
					</Link>

                    <div className={styles.socials}>
                        <FaTelegramPlane className={styles.socialsIcon} />
                        <RiWhatsappFill className={styles.socialsIcon} />
                        <SiGmail className={styles.socialsIcon} />
                    </div>
				</div>

				<div className={styles.rights}>All rights reserved</div>
			</div>
		</div>
	);
}
