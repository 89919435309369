import Header from '../../Components/Header/Header';
import styles from './homepage.module.scss';
import { Helmet } from 'react-helmet';

// components for homepage
import MainBlock from './components/MainBlock/MainBlock';
import TechStack from './components/TechStack/TechStack(main-block)';
import Websites from './components/Websites/Websites';
import Footer from '../../Components/Footer/Footer';
import FAQ from './components/FAQ/Faq';
import MainInfoModal from '../../Components/MainInfoModal/MainInfoModal';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function HomePage() {
	const modalState = useSelector(state => state.mainModal)
	console.log(modalState)
	return (
		<>
			<Helmet>
				<title>Главная || web-assist</title>
				<meta name="description" content="asdf" />
			</Helmet>
			<div className={styles.wrapper}>
				{modalState && <MainInfoModal />}
				<Header />

				<MainBlock />
				<TechStack />

				<Websites />
				<FAQ />

				{/* <Plan /> */}
				<Footer />
			</div>
		</>
	);
}
