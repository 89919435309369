import s from './stack.module.scss';
import { FaReact, FaWordpress, FaNodeJs, FaTelegram } from 'react-icons/fa';
import { SiNextdotjs } from 'react-icons/si';

export default function TechStack() {
	return (
		<>
			<h2 className={s.title}>Предлагаем к выбору широкий пул технологий</h2>
			<div className={s.stack}>
				<div className={s.tech}>
					<div className={s.reactIcon}>
						<FaReact className={s.icon} />
					</div>
					<p>React App</p>
				</div>

				<div className={s.tech}>
					<div>
						<SiNextdotjs className={s.next} />
					</div>
					<p>Next App</p>
				</div>

				<div className={s.tech}>
					<div>
						<FaWordpress className={s.icon} />
					</div>
					<p>Wordpress</p>
				</div>

				<div className={s.tech}>
					<div>
						<FaNodeJs className={s.icon} />
					</div>
					<p>Node.JS</p>
				</div>

				<div className={s.tech}>
					<div>
						<FaTelegram className={s.icon} />
					</div>
					<p>Bots </p>
				</div>
			</div>
		</>
	);
}
