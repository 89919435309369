import styles from './websites.module.scss';
import { useRef, useState } from 'react';

import tab2Image from './image 12.png';
import tab1Image from './diveces.jpg';
import tab3Image from './website.jpg';

export default function Websites() {
	const [activeTab, setActiveTab] = useState(0);

	const handleTabClick = (tabIndex, e) => {
		setActiveTab(tabIndex);
	};

	const TabImage = () => {
		switch (activeTab) {
			case 0:
				return <img width={491} height={300} src={tab1Image} alt="" />;
			case 1:
				return <img width={491} height={300} src={tab2Image} alt="" />;
			case 2:
				return <img width={491} height={300} src={tab3Image} alt="" />;
			default:
				return;
		}
	};

	const tabs = [
		{
			title: 'Адаптивно',
			description: 'Созданные нами сайты прекрасно смотрятся на любых устройствах. Телефоны, планшеты, мониторы и даже на Smart TV.',
		},
		{
			title: 'Быстро',
			description: 'Мы строго следим за качеством кода и добиваемся наилучших показателей в скорости загрузки.',
		},
		{
			title: 'Качественно',
			description: 'Семантически правильная верстка позволяет добиться лучшей индексации у поисковых роботов.',
		},
	];
	const refs = [useRef(), useRef(), useRef()];

	return (
		<div className={styles.wrapper}>
			<div className={styles.service}>
				<div className={styles.contentSide}>
					<div className={styles.titles}>
						<p className={styles.title4}>веб-разработка</p>
						<h2 className={styles.title}>Создание сайтов</h2>
					</div>

					{tabs.map((tab, index) => {
						return (
							<div
								key={index}
								className={`${styles.tab} ${activeTab === index ? styles.tabActive : null}`}
								onClick={(e) => {
									handleTabClick(index, e);
								}}
							>
								<p className={styles.tabName}>{tab.title}</p>
								<div className={styles.hider} style={{ maxHeight: activeTab === index ? refs[index].current?.clientHeight : 0 }}>
									<p className={styles.tabText} ref={refs[index]}>
										{tab.description}
									</p>
								</div>
							</div>
						);
					})}
				</div>

				<div className={styles.code}>
					<div className={styles.image}>
						<TabImage />
					</div>
				</div>
			</div>
		</div>
	);
}
